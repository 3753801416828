import { Endpoints } from './constants';
import { Instance } from './instance';

async function VerifyUser(data) {
  return new Promise((resolve, reject) => {
    Instance.get(Endpoints.VerifyUser, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function SuperAffiliateConfiguration(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.SuperAffiliateConfiguration, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const GetAffiliateUserconfigurationdetails = async (data) => {
  return new Promise((resolve, reject) => {
    Instance.get(Endpoints.GetAffiliateUserconfigurationdetails, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

async function AffiliateEmailSend(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.EmailSend, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function UpdateCountry(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.UpdateCountry, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function EditCurrency(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.EditCurrency, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function CreateUpdatePaymentMethod(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.CreateUpdatePaymentMethod, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function CreateUpdateDeliveryMethod(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.CreateUpdateDeliveryMethod, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function CreateUpdateEventCategoryAsync(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.CreateUpdateEventCategoryAsync, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function DeleteEventCategoryAsync(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.DeleteEventCategoryAsync, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function CreateUpdateEventGenresAsync(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.CreateUpdateEventGenresAsync, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function DeleteEventGenressAsync(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.DeleteEventGenressAsync, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function CreateUpdateVenueAsync(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.CreateUpdateVenueAsync, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function DeleteVenueAsync(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.DeleteVenueAsync, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function OrderAttention(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.OrderAttention, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function OrderEditDetails(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.OrderEditDetails, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const ExportOrdersCSV = async (data) => {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.ExportOrdersCSV, data, {
      responseType: 'arraybuffer',
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const DownloadTicket = async (data) => {
  return new Promise((resolve, reject) => {
    Instance.get(`${Endpoints.DownloadTicket}/${data}`, {
      responseType: 'blob',
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const PaymentRefund = async (data) => {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.PaymentRefund, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

async function TransactionAuditTrail(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.TransactionAuditTrail, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function ResendTicket(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.ResendTicket, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const GetTransactionsummary = async (data) => {
  return new Promise((resolve, reject) => {
    Instance.get(Endpoints.GetTransactionsummary, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const GetAllocations = async (data) => {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.GetAllocations, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const ProcessOrder = async (data) => {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.ProcessOrder, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

async function TransactionUpdateStatus(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.TransactionUpdateStatus, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const ProcessInvoice = async (data) => {
  return new Promise((resolve, reject) => {
    Instance.get(`${Endpoints.ProcessInvoice}/${data}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const DownloadInvoice = async (data) => {
  return new Promise((resolve, reject) => {
    Instance.get(`${Endpoints.DownloadInvoice}/${data}`, {
      responseType: 'blob',
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const SendBarcode = async (data) => {
  return new Promise((resolve, reject) => {
    Instance.get(`${Endpoints.SendBarcode}/${data}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const ResendConfirmationEmail = async (data) => {
  return new Promise((resolve, reject) => {
    Instance.get(`${Endpoints.ResendConfirmationEmail}/${data}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const ExportTicketALLOCATION = async (data) => {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.ExportTicketALLOCATION, data, {
      responseType: 'arraybuffer',
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

async function SeatAllocationInBulk(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.SeatAllocationInBulk, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function SendBulkBarcodes(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.SendBulkBarcodes, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function SendReceiptInBulk(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.SendReceiptInBulk, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const ExportWaitListCSV = async (data) => {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.ExportWaitListCSV, data, {
      responseType: 'arraybuffer',
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const TimeOutTranscationStatusCheck = async (data) => {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.TimeOutTranscationStatusCheck, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

async function SetPromoterCommissionOfTheEvent(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.SetPromoterCommissionOfTheEvent, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function ActiveDeactivePromoterAsync(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.ActiveDeactivePromoterAsync, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function ApproveRejectPromoterRequestAsync(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.ApproveRejectPromoterRequestAsync, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function SendNotification(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.SendNotification, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function CreateNotificationList(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.CreateNotificationList, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function FilterList(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.FilterList, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const DownloadEmailNotificationLog = async (data) => {
  return new Promise((resolve, reject) => {
    Instance.get(`${Endpoints.DownloadEmailNotificationLog}/${data}`, {
      responseType: 'blob',
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

async function ExistingPromotersEntryForNewEvent(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.ExistingPromotersEntryForNewEvent, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function UpdateInvoiceBankAccount(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.UpdateInvoiceBankAccount, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function AddAuditTrail(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.AddAuditTrail, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function InviteToPromoteTheEventAsync(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.InviteToPromoteTheEventAsync, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const ExportUserCSV = async (data) => {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.ExportUserCSV, data, {
      responseType: 'arraybuffer',
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const GetUserNotificationFrequencyTypes = async (data) => {
  return new Promise((resolve, reject) => {
    Instance.get(Endpoints.GetUserNotificationFrequencyTypes, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

async function SalesEmailNotificationAdminAction(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.SalesEmailNotificationAdminAction, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function UpdateSeat(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.UpdateSeat, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function Getseats(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.Getseats, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function UploadSeats(combinedData) {
  const { formData, dataParams } = combinedData;

  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.UploadSeats, formData, {
      params: dataParams,
      headers: {
        Accept: 'text/plain',
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const ExportSeatCSV = async (data) => {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.ExportSeatCSV, data, {
      responseType: 'arraybuffer',
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

async function DeleteEventProduct(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.DeleteEventProduct, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function SaveEventBasicDetails(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.SaveEventBasicDetails, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function addEventlocation(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.addEventlocation, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function AddEventDate(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.AddEventDate, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function AddEventGeneral(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.AddEventGeneral, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function UniversalSettingForEmailNotification(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.UniversalSettingForEmailNotification, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function UpdateEventSettings(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.UpdateEventSettings, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function AddEventTickets(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.AddEventTickets, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function AddEventSummary(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.AddEventSummary, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function UpdateEventSettingsSuperAffliate(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.UpdateEventSettingsSuperAffliate, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const UnallocateTicket = async (data) => {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.UnallocateTicket, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const BulkUpdateSeat = async (dataparams, data) => {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.BulkUpdateSeat, data, {
      params: dataparams,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const ResendAllocationEmail = async (data) => {
  return new Promise((resolve, reject) => {
    Instance.get(`${Endpoints.ResendAllocationEmail}/${data}`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const AddEventToTXIS = async (data) => {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.AddEventToTXIS, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

async function UpdateUserProfile(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.UpdateUserProfile, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const ChangePassword = async (data) => {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.ChangePassword, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

async function AddBank(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.AddBank, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const DeleteUserBank = async (data) => {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.DeleteUserBank, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const ActivateUserAccount = async (data) => {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.ActivateUserAccount, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const DeleteUserNotification = async (data) => {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.DeleteUserNotification, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const DeleteEmailNotificationList = async (data) => {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.DeleteEmailNotificationList, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

async function InitiateEmailVerification(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.InitiateEmailVerification, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function UserRegistration(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.UserRegistration, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const GetFurpBankProviders = async (data) => {
  return new Promise((resolve, reject) => {
    Instance.get(Endpoints.GetFurpBankProviders, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

async function FurpGetBeneficiaryRequirement(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.FurpGetBeneficiaryRequirement, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function FurpGetBank(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.FurpGetBank, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function AddEventToEventBrite(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.AddEventToEventBrite, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function UpdateSiteMaintenance(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.UpdateSiteMaintenance, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function UpdateEnquiry(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.UpdateEnquiry, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function AddEnquiry(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.AddEnquiry, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function ReplyEnquiry(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.ReplyEnquiry, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function DeteleEventDressTerm(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.DeteleEventDressTerm, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function UserDelete(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.UserDelete, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function RegenerateInvoice(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.RegenerateInvoice, null, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const GetAxsEvents = async (data) => {
  return new Promise((resolve, reject) => {
    Instance.get(Endpoints.GetAxsEvents, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

async function PublishEventExternally(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.PublishEventExternally, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function TransferTickets(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.TransferTickets, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function GetTicketToTransfer(dataParams, data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.GetTicketToTransfer, data, {
      params: dataParams,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const GetGiganticEvents = async (data) => {
  return new Promise((resolve, reject) => {
    Instance.get(Endpoints.GetGiganticEvents, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

async function TicketToRecellCancel(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.TicketToRecellCancel, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const GetImportedEventDetails = async (data) => {
  return new Promise((resolve, reject) => {
    Instance.get(Endpoints.GetImportedEventDetails, {
      params: data,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

async function CreateImportedEvent(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.CreateImportedEvent, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

async function UploadImageToS3(data) {
  return new Promise((resolve, reject) => {
    Instance.post(Endpoints.UploadImageToS3, data, {
      headers: {
        Accept: 'text/plain',
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export {
  VerifyUser,
  SuperAffiliateConfiguration,
  GetAffiliateUserconfigurationdetails,
  AffiliateEmailSend,
  UpdateCountry,
  EditCurrency,
  CreateUpdatePaymentMethod,
  CreateUpdateDeliveryMethod,
  CreateUpdateEventCategoryAsync,
  DeleteEventCategoryAsync,
  CreateUpdateEventGenresAsync,
  DeleteEventGenressAsync,
  CreateUpdateVenueAsync,
  DeleteVenueAsync,
  OrderAttention,
  OrderEditDetails,
  ExportOrdersCSV,
  DownloadTicket,
  PaymentRefund,
  TransactionAuditTrail,
  ResendTicket,
  GetTransactionsummary,
  GetAllocations,
  ProcessOrder,
  TransactionUpdateStatus,
  ProcessInvoice,
  DownloadInvoice,
  ResendConfirmationEmail,
  SendBarcode,
  ExportTicketALLOCATION,
  SeatAllocationInBulk,
  SendBulkBarcodes,
  SendReceiptInBulk,
  ExportWaitListCSV,
  TimeOutTranscationStatusCheck,
  SetPromoterCommissionOfTheEvent,
  ActiveDeactivePromoterAsync,
  ApproveRejectPromoterRequestAsync,
  SendNotification,
  CreateNotificationList,
  FilterList,
  DownloadEmailNotificationLog,
  ExistingPromotersEntryForNewEvent,
  UpdateInvoiceBankAccount,
  AddAuditTrail,
  InviteToPromoteTheEventAsync,
  ExportUserCSV,
  GetUserNotificationFrequencyTypes,
  SalesEmailNotificationAdminAction,
  UpdateSeat,
  Getseats,
  UploadSeats,
  ExportSeatCSV,
  DeleteEventProduct,
  SaveEventBasicDetails,
  addEventlocation,
  AddEventDate,
  AddEventGeneral,
  UniversalSettingForEmailNotification,
  UpdateEventSettings,
  AddEventTickets,
  AddEventSummary,
  UpdateEventSettingsSuperAffliate,
  UnallocateTicket,
  BulkUpdateSeat,
  ResendAllocationEmail,
  AddEventToTXIS,
  UpdateUserProfile,
  ChangePassword,
  AddBank,
  DeleteUserBank,
  ActivateUserAccount,
  DeleteUserNotification,
  InitiateEmailVerification,
  DeleteEmailNotificationList,
  UserRegistration,
  GetFurpBankProviders,
  FurpGetBeneficiaryRequirement,
  FurpGetBank,
  AddEventToEventBrite,
  UpdateSiteMaintenance,
  UpdateEnquiry,
  AddEnquiry,
  DeteleEventDressTerm,
  UserDelete,
  RegenerateInvoice,
  ReplyEnquiry,
  GetAxsEvents,
  PublishEventExternally,
  TransferTickets,
  GetTicketToTransfer,
  GetGiganticEvents,
  TicketToRecellCancel,
  GetImportedEventDetails,
  CreateImportedEvent,
  UploadImageToS3
};
