import { Paragraph } from 'evergreen-ui';

function ErrorStateMessage({ message = 'Sorry, something went wrong' }) {
  return (
    <Paragraph
      width="80%"
      maxWidth={360}
      marginX="auto"
      marginY={120}
      textAlign="center"
      fontSize="1.125em"
      fontStyle="italic"
      color="#A73636"
    >
      {message}
    </Paragraph>
  );
}

export default ErrorStateMessage;
