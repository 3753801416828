import { useEffect } from 'react';
import { toaster } from 'evergreen-ui';
import { useReactiveVar } from '@apollo/client';
import get from 'lodash/get';

import { authenticatedUserVar, tokenVar } from '../helpers/auth';
import { handleLogout } from '../helpers/auth';
import clientStorage from '../libs/client-storage';
import { TS_ADMIN_AUTH_TOKEN_REST_API } from '../constants';

const isJwtExpired = (currentTimestamp) => {
  const currentTimeSeconds = Math.floor(Date.now() / 1000); // Convert milliseconds to seconds
  return currentTimeSeconds >= currentTimestamp;
};

const useTokenExpiry = () => {
  const authUser = useReactiveVar(authenticatedUserVar);
  const token = useReactiveVar(tokenVar);

  useEffect(() => {
    const exp = get(authUser, 'exp');
    const checkTokenExpiry = () => {
      if (
        !!clientStorage.getItem(TS_ADMIN_AUTH_TOKEN_REST_API) &&
        isJwtExpired(exp)
      ) {
        handleLogout();
        toaster.notify('Your session has expired. Please login again.', {
          id: 'session_expired',
        });
      }
    };

    // Check token on hook initialization
    checkTokenExpiry();

    // Calculate the time remaining until the token expires
    const currentTimeSeconds = Math.floor(Date.now() / 1000);
    const timeUntilExpiry = (exp - currentTimeSeconds) * 1000; // Convert to milliseconds

    // Set up a timeout to check token expiry when it is about to expire
    if (timeUntilExpiry > 0) {
      const timeout = setTimeout(checkTokenExpiry, timeUntilExpiry);

      // Clean up the timeout on component unmount
      return () => clearTimeout(timeout);
    } else {
      // If the token has already expired, handle logout immediately
      checkTokenExpiry();
    }
  }, [authUser, token]);

  return { token, authUser }; // Return the current token and authUser if needed
};

export default useTokenExpiry;
