import { useEffect } from 'react';
import { TS_ADMIN_AUTH_TOKEN_REST_API } from '../constants';
import clientStorage from '../libs/client-storage';

const useLogoutOnTokenExpiry = () => {
  useEffect(() => {
    const handleStorageChange = (event) => {
      if (event.key === TS_ADMIN_AUTH_TOKEN_REST_API) {
        const token = clientStorage.getItem(TS_ADMIN_AUTH_TOKEN_REST_API);
        if (!token) {
          window.location.href = '/login';
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);
};

export default useLogoutOnTokenExpiry;
