import { ThemeProvider, defaultTheme } from 'evergreen-ui';

const customTheme = {
  ...defaultTheme,
  fontFamilies: {
    ...defaultTheme.fontFamilies,
    ui: 'ProximaNova, sans-serif',
    display: 'ProximaNova, sans-serif',
  },
  components: {
    ...defaultTheme.components,
    Button: {
      ...defaultTheme.components.Button,
      baseStyle: {
        ...defaultTheme.components.Button.baseStyle,
        boxShadow: 'none',
        backgroundImage: 'none',
        lineHeight: 1.2,
      },
    },
    Heading: {
      ...defaultTheme.components.Heading,
      baseStyle: {
        ...defaultTheme.components.Heading.baseStyle,
        color: 'var(--text-default-color)',
        fontFamily: 'ProximaNova, sans-serif',
      },
    },
    Paragraph: {
      ...defaultTheme.components.Paragraph,
      baseStyle: {
        ...defaultTheme.components.Paragraph.baseStyle,
        color: 'var(--text-default-color)',
        fontFamily: 'ProximaNova, sans-serif',
        fontWeight: 400,
        lineHeight: 1.2,
      },
    },
    Text: {
      ...defaultTheme.components.Text,
      baseStyle: {
        ...defaultTheme.components.Text.baseStyle,
        color: 'var(--text-default-color)',
        fontFamily: 'ProximaNova, sans-serif',
        fontWeight: 400,
        lineHeight: 1.2,
      },
    },
  },
};

function CustomEvergreenTheme(props) {
  return <ThemeProvider value={customTheme} {...props} />;
}

export default CustomEvergreenTheme;
