import axios from 'axios';
import { toaster } from 'evergreen-ui';
import { isJwtExpired } from '../helpers';
import { handleLogout } from '../helpers/auth';
import { TS_ADMIN_AUTH_TOKEN_REST_API } from '../constants';
import clientStorage from '../libs/client-storage';

const Instance = axios.create({
  baseURL: process.env.REACT_APP_REST_TS_ENDPOINT,
  timeout: 200000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json; charset=utf-8',
  },
});

function setToken(token) {
  Instance.defaults.headers.common.Authorization = `Bearer ${token}`;
}

const controller = new AbortController();
const signal = controller.signal;
// Request Interceptor
Instance.interceptors.request.use(
  async (config) => {
    const token = clientStorage.getItem(TS_ADMIN_AUTH_TOKEN_REST_API);
    config.headers.Authorization = `Bearer ${token}`;

    if (process.env.NODE_ENV === 'development') {
      console.warn(`[Request] ${config.url}: `, JSON.stringify(config.data));
    }

    // Adding the abort controller signal to the request
    config.signal = signal;
    return config;
  },
  (error) => {
    if (process.env.NODE_ENV === 'development') {
      console.error(`[Request Error] in ${error.request?._url}: `, error);
    }
    return Promise.reject(error.message);
  }
);

// Response Interceptor
Instance.interceptors.response.use(
  (response) => {
    if (process.env.NODE_ENV === 'development') {
      console.warn(
        `[Response] of ${response.config.url}: `,
        JSON.stringify(response.data)
      );
    }
    if (response.data?.responseType === 'Error') {
      return Promise.reject(response.data.message);
    }
    return response.data;
  },
  (error) => {
    if (process.env.NODE_ENV === 'development') {
      console.error(`[Response Error] in ${error.config?.url}: `, error);
    }
    const data = error.response?.data;
    const statusCode = error?.response?.status;
    let errorMessage = '';

    if (statusCode == 401) {
      handleLogout();
      toaster.notify('Your session has expired. Please login again.', {
        id: 'session_expired',
      });
    }

    if (data) {
      if (process.env.NODE_ENV === 'development') {
        console.error(
          `[Response Error.Response.Data] in ${error.config?.url}: `,
          JSON.stringify(data)
        );
      }
      if (data.data && Array.isArray(data.data) && data.data.length > 0) {
        const errorMessageArray =
          data.data.map((currentValue) => currentValue.message) || [];
        errorMessage = errorMessageArray.join('\n');
      } else if (data && data.errors && Object.keys(data.errors).length > 0) {
        errorMessage = Object.keys(data.errors)
          .map((field) => data.errors[field])
          .join('\n');
      } else {
        const errorMag = data?.error?.Message
          ? data.error.Message
          : data.message;
        errorMessage = errorMag;
      }
    } else {
      errorMessage = error.message;
    }

    return Promise.reject(errorMessage);
  }
);

export { Instance, setToken };
