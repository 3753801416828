import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client/react';
import { QueryClientProvider } from 'react-query';

import App from './components/app/app';
import { ErrorBoundary } from './libs/bugsnag-notifier';
import CustomEvergreenTheme from './libs/custom-evergreen-theme';
import ErrorStateMessage from './components/error-state-message/error-state-message';

import reportWebVitals from './reportWebVitals';
import apolloClient from './libs/apollo-client';
import queryClient from './libs/react-query-client';
import GlobalStyles from './components/global-styles/global-styles';

import 'react-datepicker/dist/react-datepicker.css';
import './assets/css/custom-datepicker.css';

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <QueryClientProvider client={queryClient}>
        <ErrorBoundary FallbackComponent={ErrorStateMessage}>
          <CustomEvergreenTheme>
            <GlobalStyles />
            <App />
          </CustomEvergreenTheme>
        </ErrorBoundary>
      </QueryClientProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
