export const TS_ADMIN_AUTH_USER = 'TS_ADMIN_AUTH_USER';
export const TS_ADMIN_AUTH_TOKEN = 'TS_ADMIN_AUTH_TOKEN';
export const TS_ADMIN_AUTH_TOKEN_REST_API = 'TS_ADMIN_AUTH_TOKEN_REST_API';

export const EXPIRED_JWT_ERROR_MESSAGE = 'Could not verify JWT: JWTExpired';
export const TS_ADMIN_CURRENT_EVENT_ID = 'TS_ADMIN_CURRENT_EVENT_ID';
export const TS_ADMIN_CURRENT_EVENT_HOST_ID = 'TS_ADMIN_CURRENT_EVENT_HOST_ID';
export const TS_ADMIN_CURRENT_EVENT_COMPLETED_STEPS =
  'TS_ADMIN_CURRENT_EVENT_COMPLETED_STEPS';
export const TS_ADMIN_CURRENT_IS_TEMPLATE = 'TS_ADMIN_CURRENT_IS_TEMPLATE';

export const PERSON_NAME_REGEX = /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g;
export const FURP_AUTH = 'FURP_AUTH';
